.logo_wrapper {
    height: 50vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20%;
  }
  
  .screen {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .screen {
    /* background-color: #55198b; */
    overflow: hidden;
    border-radius: 100%;
    animation: grow 5.5s forwards;
  }
  
  .loading {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .ball {
    width: 90px;
    height: 10px;
    margin: 7px auto;
    border-radius: 50px;
  }
  .ball:nth-child(1) {
    background: #ff005d;
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
  }
  
  .ball:nth-child(2) {
    background: #35ff99;
    -webkit-animation: left 1.1s infinite ease-in-out;
    -moz-animation: left 1.1s infinite ease-in-out;
    animation: left 1.1s infinite ease-in-out;
  }
  
  .ball:nth-child(3) {
    background: #008597;
    -webkit-animation: right 1.05s infinite ease-in-out;
    -moz-animation: right 1.05s infinite ease-in-out;
    animation: right 1.05s infinite ease-in-out;
  }
  
  .ball:nth-child(4) {
    background: #ffcc00;
    -webkit-animation: left 1.15s infinite ease-in-out;
    -moz-animation: left 1.15s infinite ease-in-out;
    animation: left 1.15s infinite ease-in-out;
  }
  
  .ball:nth-child(5) {
    background: #2d3443;
    -webkit-animation: right 1.1s infinite ease-in-out;
    -moz-animation: right 1.1s infinite ease-in-out;
    animation: right 1.1s infinite ease-in-out;
  }
  
  .ball:nth-child(6) {
    background: #ff7c35;
    -webkit-animation: left 1.05s infinite ease-in-out;
    -moz-animation: left 1.05s infinite ease-in-out;
    animation: left 1.05s infinite ease-in-out;
  }
  
  .ball:nth-child(7) {
    background: #4d407c;
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
  }
  
  @-webkit-keyframes right {
    0% {
      -webkit-transform: translate(-15px);
    }
    50% {
      -webkit-transform: translate(15px);
    }
    100% {
      -webkit-transform: translate(-15px);
    }
  }
  
  @-webkit-keyframes left {
    0% {
      -webkit-transform: translate(15px);
    }
    50% {
      -webkit-transform: translate(-15px);
    }
    100% {
      -webkit-transform: translate(15px);
    }
  }
  
  @-moz-keyframes right {
    0% {
      -moz-transform: translate(-15px);
    }
    50% {
      -moz-transform: translate(15px);
    }
    100% {
      -moz-transform: translate(-15px);
    }
  }
  
  @-moz-keyframes left {
    0% {
      -moz-transform: translate(15px);
    }
    50% {
      -moz-transform: translate(-15px);
    }
    100% {
      -moz-transform: translate(15px);
    }
  }
  
  @keyframes right {
    0% {
      transform: translate(-15px);
    }
    50% {
      transform: translate(15px);
    }
    100% {
      transform: translate(-15px);
    }
  }
  
  @keyframes left {
    0% {
      transform: translate(15px);
    }
    50% {
      transform: translate(-15px);
    }
    100% {
      transform: translate(15px);
    }
  }
  
  @keyframes grow {
    0% {
      transform: scale(0);
    }
    10% {
      transform: scale(1);
      border-radius: 0%;
      height: 100%;
      width: 100%;
    }
    90% {
      transform: scale(1);
      border-radius: 0%;
      height: 100%;
      width: 100%;
    }
    100% {
      transform: scale(0);
      transform-origin: 50% 50%;
      border-radius: 100%;
    }
  }
  
  #logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  