@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

* {
    font-family: "Open Sans";
}

/* * {
    overflow-anchor: none !important;
    scroll-snap-stop: unset !important;
    overscroll-behavior: unset !important;
    scroll-behavior: unset !important;
} */


.App {
    border-bottom: rgb(224, 202, 202);
    text-align: center;
}

.label {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: flex-start;
    color: #333333;
}

.required::after {
    content: '*';
    color: red;

}

.sub-heading {
    width: 320px;
    height: 24px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 550;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.sub-heading-upadteFeasibility {
    margin: 59px;
    width: 320px;
    height: 24px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 550;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.label-radio {
    /* left: 0%; */
    /* right: 0%; */
    /* top: 0%; */
    /* bottom: 0%; */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    /* display: flex; */
    /* align-items: flex-start; */
    color: #333333;
}

.labelfeasibilitystatus{
    margin-left: 70%;
    width: 320px;
    height: 24px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 550;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.radioColorchecked {
    background-color: #dc3545;
    border-color: #dc3545;
    border-top-color: rgb(220, 53, 69);
    border-right-color: rgb(220, 53, 69);
    border-bottom-color: rgb(220, 53, 69);
    border-left-color: rgb(220, 53, 69);
}

.cursor {
    cursor: pointer;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

}

.modal.opporunity .modal-dialog {

    margin-top: 100px;



}


.main-heading {

    width: 320px;
    height: 28px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.cust-name {
    width: 320px;
    height: 36px;
    font-weight: 600;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 28px;
    line-height: 33px;
    justify-content: center;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.hr {
    height: 2px;
    border-width: 0;
    color: rgba(255, 166, 0, 0.928);
    background-color: rgb(255, 166, 0)
}

.hr-gray {
    height: 1px;
    border-width: 0;
    color: #9E9A9A;
    background-color: #9E9A9A;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.header {
    display: flex;
    justify-content: space-between;
    z-index: 2 !important;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background: #FCFCFF;
    width: 100% !important;
    position: sticky;
    padding: 12px;
    /* width: 1512px; */
    top: 0px;
    height: 12vh;
}

.radio {
    color: red;
}

.header-logo-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    padding: 0px 48px;
}

.logos {
    width: 64px;
    height: 64px;

    background: url(assets/logo.png);

    flex: none;
    order: 0;
    flex-grow: 0
}

.user-info {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    background: url("assets/user.png");
    border: 1px solid #333333;
    flex: none;
    order: 0;
    flex-grow: 0;

}

.search-bar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.10);
    padding: 0px 36px;
    gap: 24px;
    width: 650px;
    height: 54px;
    background: #FFFFFF;
    border-bottom: 1px solid #DFDCDD;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.search-select {
    border: none;
    appearance: none;
    width: -webkit-fill-available;
    font-size: 24px;
}

.input-style {
    background-color: "#FFFBFB";
    box-shadow: "none";
    border-radius: 0;
}

.btn-invalid {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    gap: 10px;
    height: 60px;
    background: #888888;
    border: 2px solid #888888;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.2em;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 33px;
}

.btn-EditFeasiblity-invalid {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    gap: 10px;
    height: 50px;
    background: #888888;
    border: 2px solid #888888;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.2em;
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 33px;
}

.btn-EditFeasiblity-valid {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    gap: 10px;
    height: 50px;
    background: #FF9137;
    border: 2px solid #FF9137;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.2em;
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 33px;
}

.btn-valid-fill {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    gap: 10px;
    height: 60px;
    background: #FF9137;
    border: 2px solid #FF9137;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.2em;
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 33px;
}


.profile-btn-fill {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;
    width: 240px;
    background: #FFD01C;
    border-radius: 12px;
    border: 2px solid #FFD01C;
    box-shadow: 0px 4px 15px #DFDCDD;
    flex: none;
    flex-grow: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}

.create-opp-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    gap: 20px;
    border: 2px solid #FFFFFF;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 4px 15px #DFDCDD;
    flex: none;
    flex-grow: 0;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: #FF9137;

}


.table-heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0
}

.table-data {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0
}

/* .table-btn {
    box-sizing: border-box;
    justify-content: center;
    width: 200px;
    height: 40px;
    /* background: #FF9137; */
/* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15); */
/* border-radius: 12px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em; */
/* color: #FFFFFF; */
/* } */
.text-css {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.1em;
    color: #FF9137;
}

.profile-btn-unfill {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;
    width: 240px;
    height: 64px;
    background: #FFFFFF;
    border: 2px solid #FFD01C;
    box-shadow: 0px 4px 15px #DFDCDD;
    border-radius: 12px;
    flex: none;
    flex-grow: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.1em;
    color: #FFD01C;
}


.tab-css {
    width: 231px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
}

.btn-view {
    color: #fff;
    border-color: #0d6efd;
    background-color: #FF9137;
    color: white;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.015em;
    width: 220px;

    display: inline-block;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s
}

.btn-red {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    margin: 20px;
    gap: 10px;
    height: 60px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #FA0404;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    color: #FA0404;
    font-size: 24px;
    line-height: 33px;
}

.btn-table-red {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 28px;
    gap: 10px;
    width: 56px;
    height: 27px;
    background: #FFFFFF;
    border: 2px solid #FA0404;
    ;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #FA0404;
    font-size: 16px;

    /* line-height: 33px; */

}

.contactRadio {
    font-weight: "700";
}

.btn-table-black-fesibility-Invalid-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 28px;
    gap: 10px;
    width: 56px;
    height: 27px;
    background: #FFFFFF;
    border: 2px solid #AFACAC;
    ;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #AFACAC;
    font-size: 16px;

    /* line-height: 33px; */

}

.btn-sky {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 28px;
    gap: 10px;
    width: 56px;
    height: 27px;
    background: #FFFFFF;
    border: 2px solid #0886E0;
    ;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #0886E0;
    font-size: 16px;

    /* line-height: 33px; */

}

.btn-download {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 28px;
    gap: 10px;
    width: 56px;
    height: 27px;
    background: #FFFFFF;
    border: 2px solid #FA0404;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #FA0404;
    font-size: 16px;
}

.btn-modal-edit {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 10px;
    width: 36px;
    height: 16px;
    background: #FFFFFF;
    border: 2px solid #0886E0;
    ;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 500;
    color: #0886E0;
    font-size: 20px;
}

.btn-valid {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    margin: 20px;
    gap: 10px;
    height: 60px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #3CD307;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    color: #3CD307;
    font-size: 24px;
    line-height: 33px;

}

.btn-invalid-reject-feasibility {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    margin: 20px;
    gap: 10px;
    height: 60px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #FA0404;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    color: #FA0404;
    font-size: 24px;
    line-height: 33px;

}
.btn-valid-Feasibility-ContactProfile {
    /* width: 180px; */

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    margin: 20px;
    gap: 10px;
    height: 60px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #3CD307;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #3CD307;
    font-size: 24px;
    line-height: 33px;
}

.btn-valid-Feasibility-Yes {
    width: 180px;

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    margin: 20px;
    gap: 10px;
    height: 60px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #3CD307;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #3CD307;
    font-size: 24px;
    line-height: 33px;
}

.btn-valid-Feasibility-Cancle {
    width: 180px;

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    margin: 20px;
    gap: 10px;
    height: 60px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #FA0404;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #FA0404;
    font-size: 24px;
    line-height: 33px;
}

.btn-valid-orange {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    margin: 20px;
    gap: 10px;
    height: 60px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #FF9137;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #FF9137;
    font-size: 24px;
    line-height: 33px;
}

.btn-valid-orange-Feasibility-Dashboard {
    width: 280px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    margin: 20px;
    gap: 10px;
    height: 60px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #FF9137;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #FF9137;
    font-size: 20px;
    line-height: 33px;
}

.btn-valid-orange-Feasibility-Profile {
    width: 280px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    margin: 20px;
    gap: 10px;
    height: 60px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #3CD307;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #3CD307;
    font-size: 20px;
    line-height: 33px;
}


.btn-valid-yellow {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    height: 54px;
    background: #FFFFFF;
    /* border: 2px solid #AFACAC; */
    border: 2px solid #FFD01C;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #FFD01C;
    font-size: 20px;
    line-height: 33px;
}

.InvoiceTextFiledColor {

    width: 321px;
    height: 33px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;

    color: #333333;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.btn-opp {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    gap: 10px;
    height: 60px;
    border-radius: 40px;
    border: 0px solid white;
    flex: none;
    order: 0;
    flex-grow: 0;
    background-color: #DFDCDD;
    color: #FFFFFF;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.2em;
}
.swal-icon--success__ring-createfeasiblity {
    width: 112px;
    height: 112px;
    border: 4px solid hsla(98,55%,69%,.2);
    border-radius: 49%;
    box-sizing: content-box;
    position: absolute;
    left: -30px;
    top: -6px;
    z-index: 5;
}

.btn-opp-orange {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    height: 60px;
    width: fit-content;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border: 0px solid white;
    flex: none;
    order: 0;
    flex-grow: 0;
    background-color: #FF9137;
    color: #FFFFFF;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.06em;
}


.btn-opp-orange-outline {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 40px;
    gap: 10px;
    height: 60px;
    border-radius: 12px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border: 2px solid #FF9137;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #FF9137;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.2em;
}

.btn-create-center {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 40px;
    gap: 10px;
    height: 60px;
    border-radius: 12px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border: 2px solid #FF9137;
    background-color: white;
    color: #FF9137;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    width: 340px;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.06em;
}

.btn-create-customer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    height: 60px;
    width: 340px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border: 0px solid white;
    flex: none;
    order: 0;
    flex-grow: 0;
    background-color: #FF9137;
    color: #FFFFFF;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.06em;
}

.btn-create-center-disabled {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 40px;
    gap: 10px;
    height: 60px;
    border-radius: 12px;
    /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25); */
    border: 2px solid lightgray;
    background-color: white;
    color: lightgray;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    width: 340px;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.06em;
}

.table-text {
    margin-top: 1000px;
}

.btn-opp-table {
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    height: 48px;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border: 0px solid white;
    flex: none;
    order: 0;
    flex-grow: 0;
    background-color: #FF9137;
    color: #FFFFFF;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 620;
    font-size: 16px;
    /* line-height: 33px; */
    letter-spacing: 0.06em;
}

.btn-opp-table-feasibility {
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    height: 48px;
    border-radius: 8px;
    /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25); */
    border: 0px solid white;
    flex: none;
    order: 0;
    flex-grow: 0;
    background-color: #FF9137;
    color: #FFFFFF;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* line-height: 33px; */
    letter-spacing: 0.05em;
}

.btn-opp-table-white {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 10px;
    gap: 10px;
    width: 353px;
    height: 61px;
    background: #FFFFFF;
    border-radius: 12px;
    flex: none;
    order: 0;
    font-size: 24px;
    align-self: stretch;
    flex-grow: 0;
    cursor: pointer;
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.logo {
    display: flex;
    text-decoration: none;
}

.logo-text {
    width: 161px;
    height: 54px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    color: #FF9137;
    flex: none;
    order: 1;
    flex-grow: 0;
}


.logo h5 {
    margin-top: 10px;
    margin-left: 20px;
}

.logo img {
    width: 40px;
    height: 40px;
}

.menu {
    padding-top: 20px;
}

.menu ul {
    list-style: none;
    display: flex;
}

.menu ul li {
    text-decoration: none;
    justify-content: space-between;
    margin-left: 40px;
}

.menu ul li a {
    text-decoration: none;
    color: black;
}

.icons1 {
    background-color: #2650d8;
    border-radius: 50%;
    color: #fff !important;
    padding: 7px 10px;
    height: 30px !important;
    width: 30px !important;
}

.icons1 ul li a img {
    box-shadow: 5px 10px #fff;
}

.icons12 i {
    color: #2650d8;
}

.App-header {
    /* background-color: #282c34; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.radioColor {
    background-color: red;
    border-color: red;
}

.App-link {
    color: #61dafb;
}

.heading {
    color: #14877c;
    border-bottom: 1px solid #14877c;
    margin-bottom: 30px;
    padding-bottom: 10px;
}

table {
    width: 100%;
    padding: 1em;
    margin: 20px 0;
    justify-content: space-between;
}

/* the */




.welcome {
    width: 352px;
    height: 87px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    line-height: 72px;
    letter-spacing: 0.15em;
    color: #FF7809;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.user-name {
    width: 357px;
    height: 48px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.2em;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.profile-container {
    display: flex;
    flex-direction: row;
    padding: 0px 24px;
    gap: 10px;

}

.profile-container .profile-details .profile-user-name {
    font-size: 16px;
    font-weight: 500;
}

.profile-container .profile-details .profile-user-subname {
    font-size: 14px;
    font-weight: 500;
    color: rgba(68, 87, 184, 1);
}

.profile-img {
    border-radius: 50%;
    height: 54px;
    width: 54px;
}

.flexbox-row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    height: -webkit-fill-available;
}

.left-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px;
    height: 100% !important;
    line-height: 87px;
    margin-left: 20px;
}

.right-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 36px;
    /* margin-left:120px; */
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    margin-right: 8%;
    margin-bottom: 18px;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 18px;
    margin-left: 10%;

}

.card-li {
    display: flex;
    flex-direction: row !important;
    z-index: 1 !important;
    align-items: center;
    gap: 12px;
    width: 120px;
    /* height: 94.35px; */

    border-radius: none !important;
    border: none !important;
    box-shadow: 10px 10px 10px rgb(235, 230, 230);
    cursor: pointer;
}

.card {
    display: flex;
    flex-direction: row !important;
    z-index: 1 !important;
    align-items: center;
    gap: 12px;
    width: 287.42px;
    height: 94.35px;
    /* background: linear-gradient(181.48deg, rgba(255, 254, 254, 0) 29.6%, #EDE7E7 112.08%); */
    border-radius: none !important;
    border: none !important;
    box-shadow: 10px 10px 10px rgb(235, 230, 230);
    cursor: pointer;
}

.card-content {
    font-size: 24px;
}

/**
 * Main wrapper
 */
.select-search {
    width: 300px;
    position: relative;
    font-family: "Nunito Sans", sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
  * Value wrapper
  */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
  * Input
  */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
  * Options wrapper
  */
.select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
  * Options
  */
.select-search__options {
    list-style: none;
    margin: 0;
    padding-inline-start: 0;
}

/**
  * Option row
  */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
  * Option
  */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #2fcc8b;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
}

/**
  * Group
  */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    /* background: #eee; */
    padding: 8px 16px;
}

/**
  * States
  */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
  * Modifiers
  */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

.err-msg {
    color: #FA0404;
    font-size: 12px;
}

input[type="text"] {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-color: #F0E6E6;
    
}

input[type=text]:focus {
    border: 1px solid;
    border-color: #FF9137;
    scroll-behavior: unset !important;
    scroll-padding-bottom: 0;
    scroll-padding-top: 0;
    /* border-top: 1; */
  }


  input[type=password]:focus {
    border: 1px solid;
    border-color: #FF9137;
    /* border-top: 1; */
  }
  input[type=email]:focus {
    border: 2px solid;
    border-color: #FF9137;
    /* border-top: 1; */
  }

  input[type=password]:focus {
    border: 2px solid ;
    border-color: #FF9137;
    /* border-top: 1; */
  }

/* input[type="password"] {
    border-top: 0;
    border-left: 0;
    border-right: 0;
} */

select {
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

input[type="number"] {
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

input[type="date"] {
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    cursor: pointer;
}

.file-preview {
    margin: 0 10px;
}


.nav-tabs .nav-item .nav-link.active {
    /* position: relative; */
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.1em;
    color: #FF9137;
    border-color: #FF9137;
    border-width: 3px 1px 0px 1px;
    border-radius: 20px 20px 0px 0px;
    padding: 10px 15px 10px 15px;


}

.nav-tabs .nav-item .nav-link {
    /* position: relative; */
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 0.1em;
    color: #333333;
}

.modal.opporunity .modal-dialog {
    margin-top: 100px;

}



.select- {
    background-color: #FFFBFB;
    box-shadow: none;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-radius: none;
}

.swal-footer {
    display: flex;
    justify-content: center;
    /* margin:0px 15px; */
}

.swal-icon--success__ring {
    width: 80px;
    height: 80px;
    border: 4px solid hsla(98,55%,69%,.2);
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
}

.swal-icon--success:after, .swal-icon--success:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    /* background: #fff; */
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
}

.swal-icon--success__hide-corners {
    width: 5px;
    height: 90px;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: none;
}

.custom-modal-style {
    gap: 90px;
    width: 750px;
    height: 420px;
    top: 100px;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 40px #BDB7B7;
    background-color: #F5F4f4;
}

.success-modal {
    padding: 60px 40px;
}

.drop-modal {
    padding: 60px;

}

.swal-title {
    color: #333333;
    /* font-weight: 600;
    text-transform: none;
    position: relative;
    display: block;
    padding: 13px 16px;
    font-size: 27px;
    line-height: normal;
    text-align: center;
    margin-bottom: 0; */
}

.swal-text {
    color: #333333;
    font-weight: 600;
}

.custom-modal-style-fasibility-title {
    color: #000;
    gap: 90px;
    width: 750px;
    height: 420px;
    top: 100px;
    padding: 60px 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 20px #888888;
}

.custom-modal-style-drop {
    color: #000;
    gap: 90px;
    width: 750px;
    height: 420px;
    top: 120px;
    padding: 100px 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 20px #888888;
}


.message {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    align-items: center;
    text-align: center;
    color: #333333;
    flex: none;
}

.message-sub {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    color: #333333;
    flex: none;

}



.view-edit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 10px;
    align-items: center;
    height: 42px;
    border-radius: 12px;
    border: 2px solid #FFD01C;
    color: #FFD01C;
    background-color: white;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: 0.06em;
}

.delete {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
    border-radius: 12px;
    border: 2px solid #FA0404;
    color: #FA0404;
    background-color: white;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: 0.06em;
}
.delete-disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
    border-radius: 12px;
    border: 2px solid lightgray;
    color: #FFFFFF;
    background-color: lightgray;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: 0.06em;
}

.delete-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 42px;
    border-radius: 12px;
    border: 2px solid #FA0404;
    color: #FA0404;
    background-color: white;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.06em;
}

.feasibilitybuttonAddtwo {
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 40px;
    border: 2px solid #888888;
    color: #888888;
    background-color: white;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 33px;
    letter-spacing: 0.06em;
}

.btn-table-red-Delete-Address {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 40px;
    gap: 10px;
    width: 56px;
    height: 27px;
    background: #FFFFFF;
    border: 2px solid #FA0404;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #FA0404;
    font-size: 16px;
    /* line-height: 33px; */
}

.btn-table-red-Delete-Address-yellow {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 54px;
    gap: 10px;
    width: 56px;
    height: 27px;
    background: #FFFFFF;
    border: 2px solid #FFD01C;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    letter-spacing: 0.05em;
    font-style: normal;
    font-weight: 600;
    /* color: #AFACAC; */
    color: #FFD01C;
    font-size: 16px;
    /* line-height: 33px; */
}

.btn-valid-createfeasibility-page {

    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 40px;
    border: 2px solid #3CD307;
    color: #3CD307;
    background-color: white;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 33px;
    letter-spacing: 0.06em;
}

.label {
    left: 0%;
    right: 0%;
    /* top: 0%; */
    bottom: 0%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: flex-start;
    color: #333333;
}

.modal-header--sticky {
    position: sticky;
    top: 0;
    background-color: inherit;
    /* [1] */
    z-index: 1055
}

.toggle-enabled {
    width: 140px;
    height: 40px;
    font-size: 16px;
    background: #06B738;
    border:none;
    color:white;
    border-radius: 54px;
    font-weight: 600;
    letter-spacing: 0.06em;
}
.toggle-disabled {
    width: 140px;
    height: 40px;
    font-size: 16px;
    background: #888888;
    border:none;
    color:white;
    border-radius: 54px;
    font-weight: 600;
    letter-spacing: 0.06em;
}
.toggle-orange {
    width: 140px;
    height: 40px;
    font-size: 16px;
    background: #FF9137;
    border:none;
    color:white;
    border-radius: 54px;
    font-weight: 600;
    letter-spacing: 0.06em;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}